import {
    listarClientes,
    listarCantidadClientes,
    detalleCliente,
    altaCliente,
    updateCliente,
    deleteCliente,
} from '../lib/clientes-api';

const initialState = {
    clientes: [],
    detalleClienteState: null,
    totalClientes: 0
};

const LISTAR_CLIENTES = 'LISTAR_CLIENTES';
const LISTAR_TOTAL_CLIENTES = 'LISTAR_TOTAL_CLIENTES';
const ALTA_CLIENTE = 'ALTA_CLIENTE';
const DETALLE_CLIENTE = 'DETALLE_CLIENTE';
const UPDATE_CLIENTE = 'UPDATE_CLIENTE';
const DELETE_CLIENTE = 'DELETE_CLIENTE';

const listarClientesAction = (clientes) => ({ type: LISTAR_CLIENTES, payload: clientes });
const listarTotalClientesAction = (totalClientes) => ({ type: LISTAR_TOTAL_CLIENTES, payload: totalClientes });
const altaCLienteAction = (guardado) => ({ type: ALTA_CLIENTE, payload: guardado })
const detalleClienteAction = (detalleClienteState) => ({ type: DETALLE_CLIENTE, payload: detalleClienteState })
const updateClienteAction = (guardado) => ({ type: UPDATE_CLIENTE, payload: guardado })
const deleteClienteAction = (guardado) => ({ type: DELETE_CLIENTE, payload: guardado })

export const fetchListarClientes = () => {
    return (dispatch) => {
        listarClientes()
            .then(res => {
                dispatch(listarClientesAction(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchListarTotalClientes = () => {
    return (dispatch) => {
        listarCantidadClientes()
            .then(res => {
                dispatch(listarTotalClientesAction(res.total_empresas));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchDetalleCliente = (idEmpresa) => {
    return (dispatch) => {
        detalleCliente(idEmpresa)
            .then(res => {
                dispatch(detalleClienteAction(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};


export const fetchAltaCliente = (email, nombre, responsable, numeroContratoVigente, tituloContrato, descripcion) => {
    return async (dispatch) => {
        return altaCliente(email, nombre, responsable, numeroContratoVigente, tituloContrato, descripcion)
            .then(res => {
                return dispatch(altaCLienteAction(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchUpdateCliente = (id, email, nombre, responsable, numeroContratoVigente, tituloContrato, descripcion) => {
    return async (dispatch) => {
        return updateCliente(id, email, nombre, responsable, numeroContratoVigente, tituloContrato, descripcion)
            .then(res => {
                return dispatch(updateClienteAction(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchdeleteCliente = (id) => {
    return async (dispatch) => {
        return deleteCliente(id)
            .then(res => {
                return dispatch(deleteClienteAction(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};


export default (state = initialState, action) => {
    switch (action.type) {
        //en todos los casos regresamos un objeto nuevo en el cual incluimos todos las propiedades del objeto state con ...state
        case LISTAR_CLIENTES:
            return { ...state, clientes: action.payload };
        case LISTAR_TOTAL_CLIENTES:
            return { ...state, totalClientes: action.payload };
        case DETALLE_CLIENTE:
            return { ...state, detalleClienteState: action.payload };
        case ALTA_CLIENTE:
            return { ...state, guardado: action.payload };
        case UPDATE_CLIENTE:
            return { ...state, guardado: action.payload };
        case DELETE_CLIENTE:
            return { ...state, guardado: action.payload };
        default:
            return { ...state };
    }
}