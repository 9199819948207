import {
    cantidadNotificaciones,
    listarNotificaciones,
} from '../lib/notificaciones-api';

const initialState = {
    notificaciones: null,
    notificacionesNoVistas: [],
    mostrarNotificaciones: false,
    notificacionAMostrar:null,
};
const NOTFICACIONES_NO_VISTAS = 'NOTFICACIONES_NO_VISTAS';
const NOTFICACIONES = 'NOTFICACIONES';
const MOSTRARNOTIFICACIONES = 'MOSTRARNOTIFICACIONES';
const MOSTRARMODALNOTIFICACIONES = 'MOSTRARMODALNOTIFICACIONES';

const listarNotificacionesNoVistasAction = (notificacionesNoVistas) => ({ type: NOTFICACIONES_NO_VISTAS, payload: notificacionesNoVistas });
const listarNotificacionesAction = (notificaciones) => ({ type: NOTFICACIONES, payload: notificaciones });
const mostrarNotificacionesAction = (mostrarNotificaciones) => ({ type: MOSTRARNOTIFICACIONES, payload: mostrarNotificaciones });
const mostrarModalNotificacionesAction = (notificacionAMostrar) => ({ type: MOSTRARMODALNOTIFICACIONES, payload: notificacionAMostrar });

export const fetchlistarNotificacionesNoVistas = () => {
    return (dispatch) => {
        cantidadNotificaciones()
            .then(res => {
                dispatch(listarNotificacionesNoVistasAction(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchlistarNotificaciones = (cant, idUsuario, sm) => {
    return (dispatch) => {
        listarNotificaciones(cant, idUsuario, sm)
            .then(res => {
                if(res){
                    dispatch(listarNotificacionesAction(res));
                }
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchmostrarNotificaciones = (mostrarNotificaciones) => {
    return (dispatch) => {
        dispatch(mostrarNotificacionesAction(mostrarNotificaciones));
    }
}

export const fetchmostrarModalNotificaciones = (notificacionAMostrar) => {
    return (dispatch) => {
        dispatch(mostrarModalNotificacionesAction(notificacionAMostrar));
    }
}


export default (state = initialState, action) => {
    switch (action.type) {
        //en todos los casos regresamos un objeto nuevo en el cual incluimos todos las propiedades del objeto state con ...state
        case NOTFICACIONES_NO_VISTAS:
            //cambiamos el valor de la propiedad post
            return { ...state, notificacionesNoVistas: action.payload };
        case NOTFICACIONES:
            return { ...state, notificaciones: action.payload };
        case MOSTRARNOTIFICACIONES:
            return { ...state, mostrarNotificaciones: action.payload };
        case MOSTRARMODALNOTIFICACIONES:
            return { ...state, notificacionAMostrar: action.payload };
        default:
            return { ...state };
    }
}