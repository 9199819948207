import { BASEURL } from './baseurl';

export const listarEquipos = (idEmpresa) => {

	const params = {
		a: 'lq',
		e: idEmpresa
	}
	let u = new URLSearchParams(params).toString();
	const url = BASEURL + '?' + u;

	const request = {
		method: 'GET',
	};

	return fetch(url, request).then(response => response.json());
  
    /*let promise = new Promise(function(resolve, reject) {
        return resolve([{"id":"1","nombre":"Nombre Equipo ba", "seccion":"nombre Seccion", "ruta":"nombre ruta 1", "descripcion":"observaciones 1", "componentes":["#FF0000","#00FF00"], "vibraciones":{"fecha":"2021-01-19","estado":"normal","color":"#FF0000"}, "termografia":{"fecha":"2021-01-19","estado":"normal","color":"#FF0000"}},
                        {"id":"2","nombre":"Nombre Equipo 2", "seccion":"nombre Seccion 2", "ruta":"nombre ruta 2", "descripcion":"observaciones 2", "componentes":["#FF0000","#00FF00"], "vibraciones":{"fecha":"2021-01-19","estado":"normal","color":"#FF0000"}, "termografia":{"fecha":"2021-01-19","estado":"normal","color":"#FF0000"}}]);
    });
    return promise;*/
};


export const altaEquipo = (idEmpresa, nombre, seccion, ruta) => {

	const data = new FormData();
	data.append('a', 'aq');
	data.append('n', nombre);
	data.append('s', seccion);
	data.append('r', ruta);
	
	return fetch(BASEURL, {
		method: 'POST',
		body: data
	}).then(response => response.json());
	
};

export const actualizarEquipo = (idEmpresa, idEquipo, nombre, descripcion, idSeccion, idRuta) => {

	const data = new FormData();
	data.append('a', 'mq');
	data.append('id', idEquipo);
	data.append('n', nombre);
	data.append('s', idSeccion);
	data.append('d', descripcion);
	data.append('r', idRuta);
	
	return fetch(BASEURL, {
		method: 'POST',
		body: data
	}).then(response => response.json());

	/*let promise = new Promise(function(resolve, reject) {
        return resolve({"stat":"1"});
    });
    return promise;*/
};

export const eliminarEquipo = (idEquipo) => {

	const data = new FormData();
	data.append('a', 'bq');
	data.append('id', idEquipo);
	
	return fetch(BASEURL, {
		method: 'POST',
		body: data
	}).then(response => response.json());

	
};