import { BASEURL } from './baseurl';

export const cantidadNotificaciones = () => {

	const params = {
		a: 'gnv',
	}
	let u = new URLSearchParams(params).toString();
	const url = BASEURL + '?' + u;

	const request = {
		method: 'GET',
	};

	return fetch(url, request)
		.then(response => response.json());

};

export const listarNotificaciones = (pag, idUsuario, sm) => {

	const params = {
		a: 'ln',
        p:pag,
		id:idUsuario
	}
	if(sm){
		params.sm = sm;
	}

	let u = new URLSearchParams(params).toString();
	const url = BASEURL + '?' + u;

	const request = {
		method: 'GET',
	};

	return fetch(url, request)
		.then(response => response.json());

};

export const visitarNotificacion = (idNotificacion) => {
	const data = new FormData();
	data.append('a', 'snv');
	data.append('id', idNotificacion);

	return fetch(BASEURL, {
		method: 'POST',
		body: data
	}).then(response => response.json());
}

export const notificacionesVistas = () => {
	const data = new FormData();
	data.append('a', 'svc');

	return fetch(BASEURL, {
		method: 'POST',
		body: data
	}).then(response => response.json());
}

export const getDetalleNotificacion = (id) => {

	const params = {
		a: 'gnd',
        id:id
	}
	let u = new URLSearchParams(params).toString();
	const url = BASEURL + '?' + u;

	const request = {
		method: 'GET',
	};

	return fetch(url, request)
		.then(response => response.json());

};