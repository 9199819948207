import {
    listarControles,
} from '../lib/controles-api';

const initialState = {
    controles: [],
};

const LISTAR_CONTROLES = 'LISTAR_CONTROLES';

const listarControlesAction = (controles) => ({ type: LISTAR_CONTROLES, payload: controles });

export const fetchlistarControles = (idEmpresa, idEquipo, idComponente) => {
    return (dispatch) => {
        listarControles(idEmpresa, idEquipo, idComponente)
            .then(res => {
                if (res.stat == 0) {
                    window.localStorage.removeItem('usuario');
                    window.location.href = window.location.protocol + "//" + window.location.host + '/admin/user/login'
                } else {
                    dispatch(listarControlesAction(res));
                }
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        //en todos los casos regresamos un objeto nuevo en el cual incluimos todos las propiedades del objeto state con ...state
        case LISTAR_CONTROLES:
            //cambiamos el valor de la propiedad post  
            return { ...state, controles: action.payload };
        default:
            return { ...state };
    }
}