import {
    listarEquipos,
} from '../lib/equipos-api';
import moment from "moment";
const initialState = {
    equipos: null,
};

const LISTAR_EQUIPOS = 'LISTAR_EQUIPOS';
const RESET_EQUIPOS = 'RESET_EQUIPOS';
const FILSTRAR_EQUIPOS = 'FILSTRAR_EQUIPOS';

const listarEquiposAction = (equipos) => ({ type: LISTAR_EQUIPOS, payload: equipos });
const resetEquiposAction = () => ({ type: RESET_EQUIPOS });
const filtrarEquiposAction = (equipos) => ({ type: FILSTRAR_EQUIPOS, payload: equipos })

export const fetchlistarEquipos = (idEmpresa) => {
    return (dispatch) => {
        listarEquipos(idEmpresa)
            .then(res => {
                if (res.stat == 0) {
                    window.localStorage.removeItem('usuario');
                    window.location.href = window.location.protocol + "//" + window.location.host + '/admin/user/login'
                } else {
                    dispatch(listarEquiposAction(res));
                }

            }, () => {
                dispatch(listarEquiposAction([]));
            })
            .catch(res => {
                resetEquiposAction()
                console.log(res);

            })
    }
};

export const filtrarEquipos = (secciones, rutas, estados, fechas, nombreEquipo, fallas, idEmpresa) => {
    return (dispatch) => {
        listarEquipos(idEmpresa)
            .then(res => {
                let equiposFiltrados = [];
                res.forEach(equipo => {
                    equipo.seccionesSeleccionadas = secciones;
                    equipo.rutasSeleccionadas = rutas;
                    equipo.estadosSeleccionadas = estados;
                    equipo.fallasSeleccionadas = fallas;
                    equipo.fechasSeleccionadas = fechas;
                    equipo.nombreEquipo = nombreEquipo;
                })
                equiposFiltrados = res;
                dispatch(filtrarEquiposAction(equiposFiltrados));
            })
    }

}

export default (state = initialState, action) => {
    switch (action.type) {
        //en todos los casos regresamos un objeto nuevo en el cual incluimos todos las propiedades del objeto state con ...state
        case LISTAR_EQUIPOS:
            //cambiamos el valor de la propiedad post  
            return { ...state, equipos: action.payload };
        case RESET_EQUIPOS:
            return { ...state, equipos: [] };
        case FILSTRAR_EQUIPOS:
            return { ...state, equipos: action.payload };
        default:
            return { ...state };
    }
}