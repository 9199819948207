import { BASEURL } from './baseurl';

export const listarFallas = (idEmpresa) => {

	const params = {
		a: 'lf',
		e: idEmpresa
	}
	let u = new URLSearchParams(params).toString();
	const url = BASEURL + '?' + u;

	const request = {
		method: 'GET',
	};

	return fetch(url, request).then(response => response.json());
  
   };