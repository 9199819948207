import { BASEURL } from './baseurl';

export const listarEmpleados = () => {

	const params = {
		a: 'lue',
	}
	let u = new URLSearchParams(params).toString();
	const url = BASEURL + '?' + u;

	const request = {
		method: 'GET',
	};

	return fetch(url, request)
		.then(response => response.json());

};

export const altaEmpleado = (email,nombre,informes,mensajes,notificaciones, secciones) => {
	const data = new FormData();
	data.append('a', 'aue');
	data.append('e', email);
	data.append('n', nombre);
	data.append('i', informes); // 0 / 1
	data.append('m', mensajes); // 0 / 1
	data.append('t', notificaciones); // 0 / 1
	data.append('s', secciones); // [array de secciones]

	return fetch(BASEURL, {
		method: 'POST',
		body: data
	}).then(response => response.json());
}


export const bajaEmpleado = (idEmpleado) => {
	const data = new FormData();
	data.append('a', 'bue');
	data.append('id', idEmpleado);

	return fetch(BASEURL, {
		method: 'POST',
		body: data
	}).then(response => response.json());
}

export const detalleEmpleado = (id) => {

	const params = {
		a: 'gdue',
        id:id
	}
	let u = new URLSearchParams(params).toString();
	const url = BASEURL + '?' + u;

	const request = {
		method: 'GET',
	};

	return fetch(url, request)
		.then(response => response.json());

};

export const actualizarEmpleado = (id,email,nombre,informes,mensajes,notificaciones, secciones) => {
	const data = new FormData();
	data.append('a', 'mue');
    data.append('id', id);
	data.append('e', email);
	data.append('n', nombre);
	data.append('i', informes); // 0 / 1
	data.append('m', mensajes); // 0 / 1
	data.append('t', notificaciones); // 0 / 1
	data.append('s', secciones); // [array de secciones]

	return fetch(BASEURL, {
		method: 'POST',
		body: data
	}).then(response => response.json());
}