import {
    listarEmpleados,
} from '../lib/empleados-api';

const initialState = {
    empleados: null,
    empleadoABorrar:{
        id:null,
        mostrarModal:false,
        nombre:null
    },
    empleadoACrear:{
        mostrarModal:false,
        datos:null
    }
};

const LISTAR_EMPLEADOS = 'LISTAR_EMPLEADOS';
const EMPLEADO_A_BORRAR = 'EMPLEADO_A_BORRAR';
const EMPLEADO_A_CREAR = 'EMPLEADO_A_CREAR';

const listarEmpleadosAction = (empleados) => ({ type: LISTAR_EMPLEADOS, payload: empleados });
const empleadoABorrarAction = (empleado) => ({type: EMPLEADO_A_BORRAR, payload: empleado});
const empleadoACrearAction = (empleado) => ({type: EMPLEADO_A_CREAR, payload: empleado});

export const fetchlistarEmpleados = () => {
    return (dispatch) => {
        listarEmpleados()
            .then(res => {
                if (res.stat === 0) {
                    window.localStorage.removeItem('usuario');
                    window.location.href = window.location.protocol + "//" + window.location.host + '/user/login'
                } else {
                    dispatch(listarEmpleadosAction(res));
                }
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchEmpleadoABorrar = (empleadoABorrarData) => {
    return (dispatch) => {
        dispatch(empleadoABorrarAction(empleadoABorrarData));
    }
}


export const fetchEmpleadoACrear = (empleadoCrearData) => {
    return (dispatch) => {
        dispatch(empleadoACrearAction(empleadoCrearData));
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        //en todos los casos regresamos un objeto nuevo en el cual incluimos todos las propiedades del objeto state con ...state
        case LISTAR_EMPLEADOS:
            //cambiamos el valor de la propiedad post
            return { ...state, empleados: action.payload };
        case EMPLEADO_A_BORRAR:
            return {...state, empleadoABorrar:action.payload};
        case EMPLEADO_A_CREAR:
            return {...state, empleadoACrear:action.payload}
        default:
            return { ...state };
    }
}